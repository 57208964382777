import React, { useMemo, useEffect, useState, createContext, useCallback, useContext } from 'react';
import { useFeatureToggle, FEATURE_TOGGLES } from 'sharedComponents/FeatureToggle';
import useZitadelAuth from 'config/zitadel';

export const ZitadelContext = createContext(null);

export const useZitadelContext = () => {
  const zitadelContext = useContext(ZitadelContext);
  if (!zitadelContext) {
    return {};
  }
  return zitadelContext;
};

const ZitadelProvider = ({ children }) => {
  const foundationFeature = useFeatureToggle(FEATURE_TOGGLES.FOUNDATION);
  const [authorizedUser, setAuthorizedUser] = useState(null);

  const zitadel = useZitadelAuth();

  const signout = useCallback(() => {
    zitadel.signout();
  }, [zitadel]);

  useEffect(() => {
    zitadel.userManager.startSilentRenew();
  }, [zitadel]);

  const signinSilent = useCallback(() => {
    zitadel.userManager
      .signinSilent()
      .then(user => {
        if (user === null) {
          setAuthorizedUser(false);
        }
      })
      .catch(() => {
        zitadel.authorize();
      });
  }, [zitadel]);

  useEffect(() => {
    if (authorizedUser === false) {
      signinSilent();
    }
  }, [authorizedUser, signinSilent]);

  useEffect(() => {
    if (foundationFeature) {
      zitadel.userManager.events.addUserLoaded(user => {
        setAuthorizedUser(user);
      });
      zitadel.userManager.events.addSilentRenewError(e => {
        console.error('Silent renew failed:', e);
      });
    }
  }, [zitadel, authorizedUser, foundationFeature, signinSilent]);

  useEffect(() => {
    if (foundationFeature) {
      zitadel.userManager.getUser().then(user => {
        if (user && authorizedUser === null && !user.expired) {
          setAuthorizedUser(user);
        } else if (!user && authorizedUser === null) {
          setAuthorizedUser(false);
        }
      });
    }
  }, [zitadel, authorizedUser, foundationFeature]);

  const values = useMemo(
    () => ({
      user: authorizedUser,
      signout,
    }),
    [authorizedUser, signout]
  );

  return <ZitadelContext.Provider value={values}>{children}</ZitadelContext.Provider>;
};

export default ZitadelProvider;
