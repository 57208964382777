import React from 'react';
import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const NoRoles = ({ signout }) => {
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
    >
      <Typography>
        <FormattedMessage
          id="Login-Zitadel-invalidRoles"
          defaultMessage="This user doesn't have any roles in Connect. Please log in with a different user."
        />
      </Typography>
      <Button sx={{ mt: 2 }} color="error" variant="contained" onClick={signout}>
        <FormattedMessage id="Login-Zitadel-signOut" defaultMessage="Log out" />
      </Button>
    </Box>
  );
};

export default NoRoles;
