import React from 'react';
import TextField from '@mui/material/TextField';
import { FormattedMessage } from 'react-intl';
import { getProperty as get } from 'dot-prop';

const TextFieldInput = ({
  registerProps,
  errors,
  isMessageId = true,
  InputLabelProps = { shrink: true },
  variant,
  ...props
}) => {
  const { ref, name, ...rest } = registerProps;
  const message = get(errors, name)?.message;
  return (
    <TextField
      InputLabelProps={InputLabelProps}
      error={!!get(errors, name)}
      inputRef={ref}
      name={name}
      variant={variant || 'standard'}
      {...props}
      {...rest}
      helperText={message && (isMessageId ? <FormattedMessage id={get(errors, name).message} /> : message)}
    />
  );
};

export default TextFieldInput;
