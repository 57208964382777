export default function componentStyles(palette) {
  return {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: palette.surface['7'],
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        textPrimary: {
          color: palette.borders.focus,
        },
      },
      variants: [
        {
          props: { variant: 'tertiary' },
          style: {
            backgroundColor: 'red',
          },
        },
      ],
    },
    MuiPaper: {
      defaultProps: {
        square: true,
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: palette.surface['2'],
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: palette.surface['4'],
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        tabs: {
          backgroundColor: palette.surface['1'],
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderColor: palette.borders.strong,
          },
        },
        select: {
          padding: '8px 34px 8px 10px !important',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '&::-webkit-scrollbar': {
          width: '5px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: `rgba(255, 255, 255, 0.1)`,
          borderRadius: '10px',
        },
      },
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          padding: 5,
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          padding: 5,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-disabled .Mui-disabled': {
            WebkitTextFillColor: 'rgba(255, 255, 255, 0.8)',
          },
        },
      },
    },
    MUIDataTable: {
      styleOverrides: {
        liveAnnounce: {
          position: 'relative',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          borderColor: '#23343f',
          border: 0,
          '.MuiDataGrid-overlayWrapper': {
            height: 'auto !important',
            marginTop: '50px',
            marginBottom: '50px',
          },
          '.MuiDataGrid-overlayWrapperInner': {
            height: 'auto !important',
          },
        },
        columnHeader: {
          backgroundColor: '#23343f',
        },
        cell: {
          borderColor: '#23343f',
        },
      },
    },
  };
}
