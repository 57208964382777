import Duck from 'extensible-duck';
import genericEntityDuck, { withHooks } from '../../util/genericEntityDuck';
import { getActiveRole, setActiveRole } from '../api/localStorage';
import { FOUNDATION } from 'config/features';
import { FOUNDATION_OWNER_ORG, USER_ROLES } from '../domain/user';

const storeKey = 'user';
const namespace = 'app';

const getOrganizationUrlParam = () => {
  const url = new URL(window.location.toString());
  return url.searchParams.get('organization');
};

const duck = withHooks(
  genericEntityDuck(namespace, storeKey, 'id', namespace).extend({
    types: ['SET_ACTIVE_ROLE'],
    initialState: {
      data: {},
      activeRole: null,
    },
    reducer: (state, action, { types }) => {
      switch (action.type) {
        case types.SET_ACTIVE_ROLE:
          setActiveRole(action.role);
          return {
            ...state,
            activeRole: action.role,
          };
        case types.FETCH_SUCCESS: {
          const user = action.data;
          const storedRole = getActiveRole();
          const orgShortNameParam = getOrganizationUrlParam();
          const defaultOrgId = storedRole ? storedRole.organizationId : user.organizationId;
          const activeRole =
            action.data.roles.find(item => item.organizationDetails.shortName === orgShortNameParam) ||
            action.data.roles.find(item => item.organizationId === defaultOrgId) ||
            action.data.roles.find(item => item.organizationId === user.organizationId);
          setActiveRole(activeRole);
          return {
            ...state,
            activeRole,
          };
        }

        default: {
          return state;
        }
      }
    },
    selectors: {
      user: new Duck.Selector(selectors => state => selectors.data(state)),
      isInitialized: new Duck.Selector(selectors => state => selectors.initialized(state)),
      isAdmin: new Duck.Selector(selectors => state => {
        const user = selectors.user(state);
        return (
          (user &&
            user.roles &&
            user.roles.length >= 1 &&
            user.roles.some(
              ({ role, organizationId }) => user.organizationId === organizationId && role === USER_ROLES.ADMIN
            )) ||
          false
        );
      }),
      isOperator: new Duck.Selector(selectors => state => {
        const user = selectors.user(state);
        return (
          (user &&
            user.roles &&
            user.roles.length >= 1 &&
            user.roles.some(
              ({ role, organizationId }) => user.organizationId === organizationId && role === USER_ROLES.OPERATOR
            )) ||
          false
        );
      }),
      isOwner: new Duck.Selector(selectors => state => {
        const user = selectors.user(state);
        if (FOUNDATION) {
          return (
            user.shortName === FOUNDATION_OWNER_ORG &&
            user.roles &&
            user.roles.some(
              ({ role, organizationDetails }) =>
                organizationDetails.name === FOUNDATION_OWNER_ORG && role === USER_ROLES.ADMIN
            )
          );
        }
        return (
          (user.organizationId === 1 || user.organizationId === '1') &&
          user.roles &&
          user.roles.some(
            ({ role, organizationId }) => (organizationId === 1 || organizationId === '1') && role === USER_ROLES.ADMIN
          )
        );
      }),
      isAdminInOrganization: new Duck.Selector(selectors => state => {
        const user = selectors.user(state);
        const activeRole = selectors.activeRole(state);
        return (
          (user &&
            user.roles &&
            user.roles.length >= 1 &&
            user.roles.some(
              ({ role, organizationId }) => organizationId === activeRole.organizationId && role === USER_ROLES.ADMIN
            )) ||
          false
        );
      }),
      isOperatorInOrganization: new Duck.Selector(selectors => state => {
        const user = selectors.user(state);
        const activeRole = selectors.activeRole(state);
        return (
          (user &&
            user.roles &&
            user.roles.length >= 1 &&
            user.roles.some(
              ({ role, organizationId }) => organizationId === activeRole.organizationId && role === USER_ROLES.OPERATOR
            )) ||
          false
        );
      }),
      isDeployerInOrganization: new Duck.Selector(selectors => state => {
        const user = selectors.user(state);
        const activeRole = selectors.activeRole(state);
        return (
          (user &&
            user.roles &&
            user.roles.length >= 1 &&
            user.roles.some(
              ({ role, organizationId }) => organizationId === activeRole.organizationId && role === USER_ROLES.DEPLOYER
            )) ||
          false
        );
      }),
      permissions: new Duck.Selector(selectors => state => {
        return selectors.data(state).permissions;
      }),
      roles: new Duck.Selector(selectors => state => selectors.user(state).roles),
      activeRole: new Duck.Selector(selectors => state => selectors.entities(state).activeRole),
      organizationShortName: new Duck.Selector(selectors => state => {
        const activeRole = selectors.activeRole(state);
        return activeRole ? activeRole.organizationDetails.shortName : null;
      }),
    },
    creators: ({ types }) => ({
      setActiveRole: role => ({ type: types.SET_ACTIVE_ROLE, role }),
    }),
  })
);

export { duck as default, storeKey };
export const { creators, reducer, types, selectors, hooks } = duck;
