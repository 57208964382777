import settings from 'Settings/routes/routes';
import heartbeat from 'Heartbeat/routes/routes';
import datalake from 'Datalake/routes/routes';
import platform from 'Platform/routes/routes';
import assets from 'Assets/routes/routes';
import ghostwriter from 'Ghostwriter/routes/routes';
import security from 'Security/routes/routes';
import { smoc } from './externalRoutes';

const routes = [heartbeat, datalake, smoc, assets, ghostwriter, settings, platform, security];

export const navRoutes = routes.map(({ path, intlMessage, Icon, moduleNameForOrganisationAccess, external }) => ({
  path,
  intlMessage,
  Icon,
  moduleNameForOrganisationAccess,
  external,
}));

export default routes;
