import React, { useCallback } from 'react';
import { getProperty as get } from 'dot-prop';
import { useLocation, useLinkClickHandler } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import ListItemButton from '@mui/material/ListItemButton';

import { lighten, alpha } from '@mui/material/styles';
import ListItemText from '@mui/material/ListItemText';

const NavigationListItem = ({ onClose, path, intlMessage, Icon, params, external }) => {
  const location = useLocation();
  const isActive = get(location, 'pathname', '').startsWith(`/${path}`);
  const handleClick = useLinkClickHandler(path + (params ? params(location.search) : ''));
  const onClick = useCallback(
    async e => {
      await onClose();
      if (external) {
        window.location.href = path;
      } else {
        handleClick(e);
      }
    },
    [onClose, handleClick, path, external]
  );
  return (
    <ListItemButton
      onClick={onClick}
      sx={[
        theme => ({
          borderRadius: '5px',
          mb: '1px',
          padding: {
            xs: '16px',
            md: '11px',
          },
          '&:hover': {
            backgroundColor:
              theme.palette.mode === 'dark'
                ? alpha(theme.palette.primary.main, 0.3)
                : lighten(theme.palette.primary.main, 0.5),
          },
        }),
        isActive &&
          (theme => ({
            backgroundColor:
              theme.palette.mode === 'dark'
                ? alpha(theme.palette.primary.main, 0.3)
                : lighten(theme.palette.primary.main, 0.5),
          })),
      ]}
    >
      {Icon}
      <ListItemText sx={{ py: 0, px: 1, fontWeight: 600 }} primary={<FormattedMessage {...intlMessage} />} />
    </ListItemButton>
  );
};

export default NavigationListItem;
