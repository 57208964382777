import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import useZitadelAuth from 'config/zitadel';
import Loading from '../../components/Loading';
import { MODULE_PRIORITY } from 'App/domain/modules';

const Callback = () => {
  const [authorized, setAuthorized] = useState(null);
  const [redirectTo, setRedirectTo] = useState(null);

  const zitadel = useZitadelAuth();

  useEffect(() => {
    zitadel.userManager
      .signinCallback()
      .then(user => {
        if (user) {
          setRedirectTo(user.state && user.state.previous_page);
          setAuthorized(true);
        }
      })
      .catch(error => {
        console.error(error);
      });
  }, [zitadel]);
  if (authorized === true) {
    if (redirectTo && redirectTo !== '/') {
      const module = MODULE_PRIORITY.find(module => redirectTo.startsWith(module.path) && !module.external);
      if (module) {
        return <Navigate to={redirectTo} replace />;
      } else {
        window.href = redirectTo;
      }
    } else {
      return <Navigate to="/" replace />;
    }
  }
  return <Loading />;
};

export default Callback;
