import React, { useCallback, useEffect, useMemo } from 'react';
import { useLinkClickHandler, useLocation, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { navRoutes } from '../../config/routes';
import AppBar from 'sharedComponents/AppBar';
import { useFeatureToggle, FEATURE_TOGGLES } from 'sharedComponents/FeatureToggle';
import { useZitadelContext } from '../ZitadelProvider';
import { MODULE_PATH, SETTINGS } from 'Heartbeat/routes/routes';

const Navigation = ({ organizationShortName, fetchFeatures, logout, modules }) => {
  const intl = useIntl();
  const foundation = useFeatureToggle(FEATURE_TOGGLES.FOUNDATION);
  const { signout: signoutZitadel } = useZitadelContext();

  const onProfileClick = useLinkClickHandler(`/${MODULE_PATH}/${SETTINGS}`);
  useEffect(() => {
    if (organizationShortName && !foundation) {
      fetchFeatures();
    }
  }, [fetchFeatures, organizationShortName, foundation]);
  const navigate = useNavigate();
  const location = useLocation();
  const current = location.pathname.split('/')[1];

  const routes = useMemo(
    () =>
      navRoutes
        .filter(route =>
          route.moduleNameForOrganisationAccess ? modules.includes(route.moduleNameForOrganisationAccess) : true
        )
        .map(({ path, intlMessage, external }) => ({
          path,
          label: intl.formatMessage(intlMessage),
          external,
        })),
    [intl, modules]
  );
  const onSelect = useCallback(
    e => {
      const selectedModule = routes.find(route => route.path === e.target.value);
      if (!selectedModule.external) {
        navigate('/' + e.target.value);
      } else {
        window.location.href = e.target.value;
      }
    },
    [navigate, routes]
  );

  const labels = useMemo(
    () => ({
      title: intl.formatMessage({ id: 'general.gridos', defaultMessage: 'GridOS' }),
      product: intl.formatMessage({ id: 'general.platform', defaultMessage: 'Platform' }),
      userButton: intl.formatMessage({ id: 'Toasts-ThemeChange-action', defaultMessage: 'User settings' }),
      logout: {
        label: intl.formatMessage({ id: 'Logout-modalButton-confirmLogout', defaultMessage: 'Log out' }),
        confirmTitle: intl.formatMessage({ id: 'Logout-modalHeader-confirmLogout', defaultMessage: 'Confirm logout' }),
        confirmBody: intl.formatMessage({
          id: 'Logout-modalContent-confirmLogout',
          defaultMessage: 'Are you sure you want to log out?',
        }),
        confirmButton: intl.formatMessage({ id: 'Logout-modalButton-confirmLogout', defaultMessage: 'Log out' }),
      },
    }),
    [intl]
  );
  return (
    <>
      <AppBar
        modules={routes}
        currentModulePath={current}
        labels={labels}
        onProfileClick={onProfileClick}
        onLogout={foundation ? signoutZitadel : logout}
        onSelect={onSelect}
      />
    </>
  );
};

export default Navigation;
