import { FOUNDATION } from 'config/features';

export const MODULES = ['HEARTBEAT', 'DATA_LAKE', 'SMOC', 'ASSETS', 'GHOSTWRITER', 'EVE', 'PLATFORM', 'SECURITY'];

export const MODULE_PRIORITY = [
  {
    key: 'HEARTBEAT',
    path: FOUNDATION ? '/connect' : '/heartbeat',
  },
  {
    key: 'DATA_LAKE',
    path: '/datalake',
  },
  {
    key: 'ASSETS',
    path: '/assets',
  },
  {
    key: 'SMOC',
    path: '/smoc',
    external: true,
  },
  {
    key: 'GHOSTWRITER',
    path: '/ghostwriter',
  },
  {
    key: 'PLATFORM',
    path: '/platform',
  },
  {
    key: 'SECURITY',
    path: '/security',
  },
];
