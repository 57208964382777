import { connect } from 'react-redux';
import { selectors as configurationSelectors } from '../../ducks/configurationDuck';
import { selectors as userSelectors } from '../../ducks/userDuck';
import { selectors as featureSelectors } from '../../ducks/featureDuck';
import { selectors as authSelectors } from '../../ducks/authDuck';
import { selectors as tokenSelectors } from 'store/jwtMiddleware/tokenDuck';
import { featuresAction as fetchFeatures } from '../../actions/features';
import { initializeFoundation } from '../../actions/zitadel';
import { initialize } from '../../actions/init';

import Initialize from './Initialize';

const mapStateToProps = state => ({
  configurationInitialized: configurationSelectors.initialized(state) || !!configurationSelectors.fetchError(state),
  user: userSelectors.user(state),
  organizationShortName: userSelectors.organizationShortName(state),
  featuresInitialized: featureSelectors.initialized(state) || !!featureSelectors.fetchError(state),
  token: authSelectors.token(state),
  invalidRoles: tokenSelectors.invalidRoles(state),
});

const mapDispatchToProps = {
  fetchFeatures,
  initializeFoundation: initializeFoundation,
  initialize: initialize,
};

export default connect(mapStateToProps, mapDispatchToProps)(Initialize);
