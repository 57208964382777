import Duck, { Selector } from 'extensible-duck';
import { getLocalStorageToken } from './localStorage';

const storeKey = 'jwt';
const namespace = 'token';

const emptyToken = {
  token: null,
  refreshToken: null,
  expires: null,
};

const duck = new Duck({
  namespace,
  store: storeKey,
  types: ['TOKEN_REFRESH_FAILED', 'TOKEN_REFRESH_SUCCESS', 'CLEAR_TOKEN', 'SET_TOKEN', 'SET_INVALID_ROLES'],
  initialState: {
    ...(getLocalStorageToken() || emptyToken),
    invalidRoles: false,
    error: null,
  },
  reducer: (state, action, { types, initialState }) => {
    switch (action.type) {
      case types.SET_TOKEN:
      case types.TOKEN_REFRESH_SUCCESS:
        return {
          ...initialState,
          token: action.token,
          refreshToken: action.refreshToken,
          expires: action.expires,
        };
      case types.TOKEN_REFRESH_FAILED:
        return {
          ...initialState,
          ...emptyToken,
          error: action.error,
        };

      case types.CLEAR_TOKEN:
        return {
          ...initialState,
          ...emptyToken,
        };

      case types.SET_INVALID_ROLES:
        return {
          ...initialState,
          ...emptyToken,
          invalidRoles: true,
        };

      default:
        return state;
    }
  },
  selectors: {
    root: state => state[namespace][storeKey],
    token: new Selector(selectors => state => selectors.root(state).token),
    refreshToken: new Selector(selectors => state => selectors.root(state).refreshToken),
    expires: new Selector(selectors => state => selectors.root(state).expires),
    tokenObject: new Selector(selectors => state => ({
      token: selectors.token(state),
      refreshToken: selectors.refreshToken(state),
      expires: selectors.expires(state),
    })),
    error: new Selector(selectors => state => selectors.root(state).error),
    invalidRoles: new Selector(selectors => state => selectors.root(state).invalidRoles),
  },
  creators: ({ types }) => ({
    tokenRefreshSuccess: (token, refreshToken, expires) => ({
      type: types.TOKEN_REFRESH_SUCCESS,
      token,
      refreshToken,
      expires,
    }),
    tokenRefreshFailed: error => ({ type: types.TOKEN_REFRESH_FAILED, error: error.message }),
    clearToken: () => ({ type: types.CLEAR_TOKEN }),
    setToken: (token, refreshToken, expires) => ({ type: types.SET_TOKEN, token, refreshToken, expires }),
    setInvalidRoles: () => ({ type: types.SET_INVALID_ROLES }),
  }),
});

export { duck as default, storeKey, namespace };
export const { creators, reducer, types, selectors } = duck;
