import { useMemo } from 'react';
import { defineMessage } from 'react-intl';
import SecurityIcon from '@mui/icons-material/Security';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import AdminPanelSettingsOutlined from '@mui/icons-material/AdminPanelSettingsOutlined';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import ComputerIcon from '@mui/icons-material/Computer';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import MODULE_NAMES from 'App/config/moduleNames';
import { FEATURE_TOGGLES } from '../../App/ducks/featureDuck';

export const MODULE_PATH = 'security';

export const SESSIONS = '';
export const AUTHENTICATION = 'authentication/*';
export const AUTHORIZATION = 'authorization/*';
export const SESSION_MANAGEMENT = 'session-management/*';
export const KNOWN_CLIENTS = 'known-clients';
export const LOGIN_UI_CUSTOMIZATION = 'login-ui-customization';

const children = [
  {
    path: SESSIONS,
    index: true,
    lazy: () => import(/* webpackChunkName: "Security:Sessions" */ '../scenes/Sessions'),
    intlMessage: defineMessage({ id: 'route.security.sessions', defaultMessage: 'Sessions' }),
    Icon: VerifiedUserOutlinedIcon,
  },
  {
    path: AUTHENTICATION,
    lazy: () => import(/* webpackChunkName: "Security:Authentication" */ '../scenes/Authentication'),
    intlMessage: defineMessage({ id: 'route.security.authentication', defaultMessage: 'Authentication' }),
    Icon: AdminPanelSettingsOutlined,
    featureToggle: FEATURE_TOGGLES.SECURITY_IDP,
  },
  {
    path: AUTHORIZATION,
    lazy: () => import(/* webpackChunkName: "Security:Authorization" */ '../scenes/Authorization'),
    intlMessage: defineMessage({ id: 'route.security.authorization', defaultMessage: 'Authorization' }),
    Icon: ShieldOutlinedIcon,
  },
  {
    path: SESSION_MANAGEMENT,
    lazy: () => import(/* webpackChunkName: "Security:SessionManagement" */ '../scenes/SessionManagement'),
    intlMessage: defineMessage({ id: 'route.security.sessionManagement', defaultMessage: 'Session Management' }),
    Icon: HubOutlinedIcon,
  },
  {
    path: KNOWN_CLIENTS,
    lazy: () => import(/* webpackChunkName: "Security:KnownClients" */ '../scenes/KnownClients'),
    intlMessage: defineMessage({ id: 'route.security.knownClients', defaultMessage: 'Known Clients' }),
    Icon: ComputerIcon,
  },
  {
    path: LOGIN_UI_CUSTOMIZATION,
    lazy: () => import(/* webpackChunkName: "Security:LoginUICustomization" */ '../scenes/LoginUICustomization'),
    intlMessage: defineMessage({ id: 'route.security.loginUICustomization', defaultMessage: 'Login UI Customization' }),
    Icon: EditOutlinedIcon,
    featureToggle: FEATURE_TOGGLES.SECURITY_IDP,
  },
];

const security = {
  path: MODULE_PATH,
  lazy: () => import(/* webpackChunkName: "Security" */ 'Security'),
  intlMessage: defineMessage({ id: 'route.security', defaultMessage: 'Security' }),
  Icon: <SecurityIcon />,
  moduleNameForOrganisationAccess: MODULE_NAMES.SECURITY,
  children,
};

export const useRoutes = () =>
  useMemo(
    () => ({
      intlMessage: security.intlMessage,
      root: security.path,
      Icon: security.Icon,
      moduleNameForOrganisationAccess: security.moduleNameForOrganisationAccess,
      routes: security.children.map(({ path, featureToggle, intlMessage, Icon, restrictRoleAccess, index }) => ({
        path,
        intlMessage,
        featureToggle,
        Icon,
        restrictRoleAccess,
        index,
      })),
    }),
    []
  );

export default security;
