/* global __APP_VERSION__ */
import React, { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';
import { useFeatureToggle, FEATURE_TOGGLES } from 'sharedComponents/FeatureToggle';
import AppFallbackUI from './FallbackUI/AppFallbackUI';
import SceneFallbackUI from './FallbackUI/SceneFallbackUI';
import { errorLogging } from '../../api/loggingApi';

const sendErrorReport = async (error, signal) => {
  const errorInfo = {
    userAgent: window.navigator.userAgent,
    errorMessage: `${error.toString()}\n\n${error.stack}`,
    clientVersion: __APP_VERSION__,
    currentUrl: window.location.href,
  };
  try {
    await errorLogging(errorInfo, signal);
  } catch {
    console.error('Error logging failed');
  }
};

const ErrorBoundary = ({ appFallbackUI }) => {
  const isFoundation = useFeatureToggle(FEATURE_TOGGLES.FOUNDATION);
  const error = useRouteError();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (error && !import.meta.env.DEV) {
      sendErrorReport(error, signal);
    }
    return () => {
      controller.abort();
    };
  }, [error, isFoundation]);
  return appFallbackUI ? <AppFallbackUI /> : <SceneFallbackUI />;
};

export default ErrorBoundary;
